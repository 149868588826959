const config = {
  "broker": "1",
  "name": "Thai",
  "registrationBundle": "thai",
  "defaultCountryName": "Thailand",
  "defaultCountryCode": "TH",
  "fxgeoip_code": "TH",
  languageCode: 'th',
  languageName:'Thai',
  spokenLanguageCode:'th',
  direction:'ltr',
  footerDisclaimer:'730',
  brand: 'iforex'
};
module.exports = config;